import { connect } from 'react-redux';

import { LinkGenerator } from './linkGenerator';
import { getCurrencies } from '../../actions';

const mapStateToProps = (state) => {
  return {
    currencies: state.ui.currencies
  }
}

const VisibleLinkGenerator = connect(mapStateToProps, {
  getCurrencies
})(LinkGenerator);

export default VisibleLinkGenerator;
