import { connect } from 'react-redux';

import { Widget } from './widget';
import { getMerchant } from '../../actions';

const mapStateToProps = (state) => {
  return {
    merchant: state.ui.merchant
  }
}

const VisibleWidget = connect(mapStateToProps, {
  getMerchant
})(Widget);

export default VisibleWidget;
