import React from 'react';
import { Icon } from 'semantic-ui-react';

let view = () => {
    return (
        <Icon name='circle notch' loading size='big' style={{ color: '#009c4d', margin: 10 }} />
    );
}

export default view;
