import React from 'react';
import Loading from '../loading';

let view = () => {
  return (
    <div className="animated fadeIn payment_widget">
      <div className="widget_panel_backdrop">
      </div>
      <div className="widget_panel_container payment_steps">
        <div className='widget_panel_card pay_step step_active text-center'>
          <Loading />
        </div>
      </div>
    </div>
  );
}

export default view;
