import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Widget from '../Widget';
import Loader from '../Widget/loader';
import LinkGenerator from '../LinkGenerator';

export class Main extends Component {
  render() {
    return (
      <Router basename={process.env.ROUTER_BASE_PATH}>
        <div>
          <Route path='/payment/:data' exact component={Widget} />
          <Route path='/linkgenerator' exact component={LinkGenerator} />
          <Route path='/loader' exact component={Loader} />
        </div>
      </Router>
    );
  }
}
