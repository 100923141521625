import React from 'react';
import ReactDOM from 'react-dom';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

window.matomo = createInstance({
    urlBase: "https://analytics.afrivelle.com/",
    siteId: 5,
});

ReactDOM.render(
    <MatomoProvider value={window.matomo}>
        <App />
    </MatomoProvider>,
    document.getElementById('root'));

registerServiceWorker();
