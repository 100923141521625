import {
  RES_GET_CURRENCIES,
  RES_GET_MERCHANT
} from '../actions/types';

const initialState = {
  currencies: [],
  merchant: {}
};

export const UIReducer = (state = initialState, action) => {
  switch (action.type) {
    case RES_GET_CURRENCIES:
      return {
        ...state,
        currencies: action.error ? state.currencies : action.currencies
      }
    case RES_GET_MERCHANT:
      return {
        ...state,
        merchant: action.error ? state.merchant : action.merchant
      }
    default:
      return state;
  }
}
