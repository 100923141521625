import React, { Component } from 'react';
import { Button, Image, Header, Icon, Popup } from 'semantic-ui-react';
import { get, find, has, map, capitalize, replace, pick } from 'lodash';
import qs from "query-string";
import useMatomo from '../../matomo'
import CopyToClipboard from 'react-copy-to-clipboard';
import axios from 'axios';
import Loading from '../loading';
import './widget.css';

let api = process.env.REACT_APP_API_URL;
let countDown = 5 * 60 * 1000;

export class Widget extends Component {
	state = {
		error: false,
		errorMessage: '',
		starting: true,
		busy: true,
		txList: [],
		data: null,
		currentChoice: null,
		countDown: null,
		merchant: null,
		stage: 0,
		timeLeft: { min: 0, sec: 0 },
		transaction: null,
		complete: false,
		addressCopied: false,
		amountCopied: false,
		memoCopied: false
	};

	constructor(props) {
		super(props);

		const matomo = useMatomo();

		this.trackPageView = matomo.trackPageView;
		this.trackEvent = matomo.trackEvent;
	}

	componentDidMount() {
		const query = qs.parse(this.props.location.search);

		this.trackPageView({
			documentTitle: `Pay with Paylot - ${query.r || 'N/A'}`
		});
		this.trackEvent({ category: 'payment', action: 'on-load' });

		let data = get(this.props, 'match.params.data');

		try {
			data = atob(data);
			data = JSON.parse(data);

			this.trackEvent({ category: 'payment', action: 'on-merchant-fetch' });

			this.props.getMerchant(
				{ id: data.merchant, key: data.key },
				(err, merchant) => {
					if (!err) {
						data.merchant = merchant._id;

						this.trackEvent({ category: 'payment', action: 'on-merchant-fetched' });

						this.trackEvent({ category: 'payment', action: 'on-process' });

						axios({
							method: 'POST',
							url: `${api}/process`,
							data
						})
							.then(res => {
								this.trackEvent({ category: 'payment', action: 'on-process-done' });

								this.setState(
									{
										data,
										busy: false,
										starting: false,
										error: false,
										txList: res.data,
										countDown: Date.now() + countDown
									},
									() => {
										// start refresher
										this.startRefresh();
										this.startSocket();
									}
								);
							})
							.catch(err => {
								this.trackEvent({ category: 'payment', action: 'on-process-error' });

								console.log(err.response);
								let errorMessage = 'Could not process transaction data';

								if (
									err.response &&
									err.response.data &&
									err.response.status === 400
								) {
									errorMessage = err.response.data.message;
									errorMessage =
										errorMessage.charAt(0).toUpperCase() +
										errorMessage.substr(1);
								}

								this.setState({ error: true, busy: false, errorMessage });
							});
					}
				}
			);
		} catch (error) {
			console.log(error);
			this.setState({
				error: true,
				busy: false,
				errorMessage: 'Could not read transaction data'
			});
		}
	}

	getCurrencyData(id) {
		let data = find(this.state.txList, t => t.currencyData._id === id);
		return get(data, 'currencyData') || {};
	}

	startSocket() {
		let url = replace(api, 'http', 'ws');
		let socket = (this.socket = new WebSocket(url));
		socket.onmessage = ev => {
			let data = JSON.parse(ev.data);
			data = data.update.transaction;
			if (this.state.transaction._id === data._id && data.sent) {
				this.setState({ complete: true });
				setTimeout(() => {
					this.close(JSON.stringify(this.state.transaction));
				}, 5000);
			}
		};
		socket.onopen = () => {
			console.log('connected to', url);
		};
		socket.onclose = () => {
			console.log('disconnected from', url);
			clearInterval(this.socketPingInterval);
			this.startSocket();
		};
		this.socketPingInterval = setInterval(() => {
			if (socket.OPEN) {
				try {
					socket.send('');
				} catch (error) {
					console.log(error);
				}
			}
		}, 5000);
	}

	confirmTransaction(tx) {
		return new Promise((resolve, reject) => {
			this.trackEvent({ category: 'payment', action: 'on-confirm-tx' });

			axios({
				method: 'GET',
				url: `${api}/transactions/${tx._id}`
			})
				.then(res => {
					this.trackEvent({ category: 'payment', action: 'on-confirm-tx' });

					if (res.data.sent) {
						resolve(res.data);
					} else {
						reject(res.data);
					}
				})
				.catch(err => {
					this.trackEvent({ category: 'payment', action: 'on-confirm-tx-error' });
					console.log(err);
					reject(err);
				});
		});
	}

	wait() {
		this.trackEvent({ category: 'payment', action: 'on-wait' });
		this.setState(
			{
				stage: 1,
				error: false,
				countDown: Date.now() + countDown
			},
			() => {
				this.startRefresh();
			}
		);
	}

	onCopied(item) {
		this.trackEvent({ category: 'payment', action: `on-copied-${item}` });
		let property = `${item}Copied`;
		this.setState({ [property]: true });
		setTimeout(() => {
			this.setState({ [property]: false });
		}, 500);
	}

	startRefresh() {
		this.refresh();
		this.refreshInterval = setInterval(() => {
			this.refresh();
		}, 1000);
	}

	refresh() {
		let diff = this.state.countDown - Date.now();
		let min = Math.floor(diff / (60 * 1000));
		let sec = Math.floor(diff / 1000) % 60;
		if (diff < 0) {
			if (this.state.stage === 1) {
				// manually confirm tx before reloading
				clearInterval(this.refreshInterval);
				this.confirmTransaction(this.state.transaction)
					.then(() => {
						this.setState({ complete: true });
						setTimeout(() => {
							const transaction = pick(
								this.state.transaction,
								'reference',
								'currency',
								'storeAs',
								'payload',
								'amount',
								'amountSent',
								'nairaValue',
								'sent',
								'confirmed',
								'valid',
								'subaccount'
							);
							this.close(JSON.stringify(transaction));
						}, 5000);
					})
					.catch(() => {
						this.setState({
							busy: false,
							error: true,
							errorMessage: 'Could not confirm transaction on time'
						});
					});
			} else {
				window.location.reload(true);
			}
		} else {
			this.setState({ timeLeft: { min, sec } });
		}
	}
	isEmpty = obj => {
		for (const key in obj) {
			if (obj.hasOwnProperty(key))
				return false;
		}
		return true;
	};

	getQRImage(tx) {
		let currencyData = this.getCurrencyData(tx.currency);
		// console.log('Currency Data ', currencyData)
		if(this.isEmpty(currencyData)) {
			return null;
		}

		if (has(tx, 'destination.memo')) {
			return `https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${tx.destination.address}`;
		}
		return `https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${currencyData.name.toLowerCase()}:${
			tx.destination.address
			}?amount=${tx.amount}`;
	}

	getWalletUri() {
		const tx = this.state.transaction;
		let currencyData = this.getCurrencyData(tx.currency);

		return `${currencyData.name.toLowerCase()}:${
			tx.destination.address
			}?amount=${tx.amount}`;
	}

	getCurrencySymbol(id) {
		let currencyData = this.getCurrencyData(id);
		return currencyData.symbol;
	}

	close(data) {
		let widget = document.getElementById('widget');
		widget.classList.remove('fadeIn');
		widget.classList.add('fadeOut');
		setTimeout(() => {
			window.parent.postMessage(data, '*');
		}, 2000);
	}

	selectCurrency(tx) {
		
		if (!tx) return;
		this.trackEvent({ category: 'payment', action: 'on-select-currency' });
		clearInterval(this.refreshInterval);
		this.setState({ busy: true }, () => {
			axios({
				method: this.state.transaction ? 'PUT' : 'POST',
				url: this.state.transaction
					? `${api}/transactions/${this.state.transaction._id}`
					: `${api}/transactions`,
				data: tx
			})
				.then(res => {
					const qrCode = this.getQRImage(res.data)
					console.log('qrCode ', qrCode, res.data)
					this.setState(() => ({busy: false, stage:1, transaction: res.data, countDown: Date.now() + countDown}), () => {
						this.startRefresh();
					})
					// this.setState(
					// 	{
					// 		busy: false,
					// 		transaction: res.data,
					// 		stage: 1,
					// 		countDown: Date.now() + countDown
					// 	},
					// 	() => {
					// 		this.startRefresh();
					// 	}
					// );
				})
				.catch(err => {
					console.log(err);
					this.setState({
						busy: false,
						error: true,
						errorMessage: 'Could not process transaction data'
					});
				});
		});
	}

	formatAmount(amount) {
		return amount.toLocaleString({}, { maximumFractionDigits: 7 });
	}
	renderLoading = () => {
		const { busy } = this.state
		if(busy) {
			return (
				<div className="widget_panel_card pay_step step_active text-center">
					<Loading />
				</div>
			)
		}
	}
	renderTimeErrorCard = () => {
		const { error, errorMessage } = this.state
		if(error && errorMessage === 'Could not confirm transaction on time') {
			return (
				<div className="success_message">
					<h3>Payment not confirmed</h3>
					<div className="stat_image">
						<img src="/img/banners/failure.svg" alt="Payment not confirmed" />
					</div>
					<p>
						We were unable to confirm your payment. If you have made
						payment, click wait to continue waiting else try again.
					</p>
					<div className="action_btn">
						<button className="btn btn-primary" onClick={() => this.wait()}>
							Wait
						</button>
					</div>
					<div className="action_btn">
						<button className="btn btn-default" style={{ color: 'grey' }} onClick={() => window.location.reload(true)}>
							Try again
						</button>
					</div>
				</div>
			)
		}
	}
	renderFailedErrorCard = () => {
		const { error, errorMessage } = this.state
		if(error && errorMessage !== 'Could not confirm transaction on time') {
			return (
				<div className="success_message">
					<h3>Payment not successful</h3>
					<div className="stat_image">
						<img
							src="/img/banners/failure.svg"
							alt="Payment not confirmed"
						/>
					</div>
					<p>
						{this.state.errorMessage ||
							'We were unable to process your payment. Please, try again.'}
					</p>
					<div className="action_btn">
						<button
							className="btn btn-primary"
							onClick={() => window.location.reload(true)}
						>
							Try again
						</button>
					</div>
				</div>
			)
		}
		
	}
	renderFirstStage = () => {
		const { busy, error, complete, stage } = this.state
		if(!busy && !error && !complete && stage === 0) {
			return (
				<section className={`widget_panel_card pay_step step_active`}>
					<header className="widget_panel_header">
						<div className="flex_container">
							<figure className="pay_merchant_logo">
								<img
									src={get(
										this.props.merchant,
										'logo.url',
										'/img/logos/dummy_logo_1.png'
									)}
									alt="Business Logo"
								/>
							</figure>
							<div className="pay_merchant_details">
								<h4 className="merchant_title">
									{capitalize(this.props.merchant.name)}
								</h4>
								{this.state.currentChoice && (
									<div className="purchase_amount font-bold">
										{get(
											this.state.currentChoice,
											'currencyData.symbol'
										) !== get(this.state.data, 'currency') && (
												<div className="amount_item">
													{/* <i className="currency naira"></i> */}
													{this.formatAmount(
														get(this.state.data, 'amount', 0.0)
													)}{' '}
													{get(this.state.data, 'currency')}
													{/* {get(first(this.state.txList), 'nairaValue', '****')} */}
												</div>
											)}
										<div className="amount_item">
											{this.formatAmount(
												get(this.state.currentChoice, 'amount', 0.0)
											)}{' '}
											{get(
												this.state.currentChoice,
												'currencyData.symbol'
											)}
										</div>
									</div>
								)}
								<button
									className="close_dialog"
									onClick={this.close.bind(this, 'close')}
								/>
							</div>
						</div>
					</header>
					<div className="alert_banner" role="alert">
						<div className="banner_label">
							{/* <span className="text-caps">Time to Refresh : </span> */}
							<span className="timer">
								{this.state.timeLeft.min}m:{this.state.timeLeft.sec}s
							</span>
							<span> remaining</span>
						</div>
						<span className="alert_icon">
							<svg
								className="cust_icon icon_xs"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
							>
								<path
									fill="#e5e5e5"
									d="M10,20A10,10,0,1,1,20,10,10,10,0,0,1,10,20Z"
								/>
								<rect
									fill="#"
									x="8.75"
									y="8.88"
									width="2.5"
									height="6.75"
								/>
								<rect
									fill="#"
									x="8.75"
									y="4.38"
									width="2.5"
									height="2.25"
								/>
							</svg>
						</span>
					</div>
					<div className="widget_panel_body">
						<h4 className="step_title">Select a payment option</h4>
						<div className="payment_option_tiles custom_selector">
							{map(this.state.txList, (tx, i) => {
								return (
									<article
										key={i}
										className={`option_tile selector_item ${
											get(this.state, 'currentChoice.currency') ===
												tx.currency
												? 'selected'
												: ''
											}`}
										onClick={e => {
											this.trackEvent({
												category: 'payment',
												action: `select-currency-${tx.currencyData.symbol}`
											});
											this.setState({ currentChoice: tx });
										}}
									>
										<label className="wrapper">
											<input
												type="radio"
												className="cst_selector"
												name="us_options"
											/>
											<div className="option_label">
												{tx.currencyData.name}
											</div>
											<div className="option_icon">
												<Image
													size="mini"
													src={`/lib/SVG/${tx.currencyData.symbol}.svg`}
												/>
											</div>
											<div className="payment_value">
												{this.formatAmount(tx.amount)}{' '}
												{tx.currencyData.symbol}
											</div>
										</label>
									</article>
								);
							})}
						</div>
					</div>
					<footer className="widget_panel_footer">
						<div className="action_btn">
							<button
								disabled={!this.state.currentChoice}
								className="btn btn-primary"
								onClick={() =>
									this.selectCurrency(this.state.currentChoice)
								}
							>
								Get Payment Address
							</button>
						</div>
						<div className="foot_note text-center">
							<span>Secured by Paylot</span>
						</div>
					</footer>
				</section>
			)
		}
	}

	renderNextStage = () => {
		const { busy, error, complete, stage } = this.state
		if(!busy && !error && !complete && stage === 1) {
			return (
				<section className={`widget_panel_card pay_step step_active`}>
					<header className="widget_panel_header">
						<div className="flex_container">
							<figure className="pay_merchant_logo">
								<img src={get( this.props.merchant, 'logo.url', '/img/logos/dummy_logo_1.png' )} alt="Business Logo" />
							</figure>
							<div className="pay_merchant_details">
								<h4 className="merchant_title">{capitalize(this.props.merchant.name)}</h4>
								<div className="purchase_amount font-bold">
									{get(
										this.state.currentChoice,
										'currencyData.symbol'
									) !== get(this.state.data, 'currency') && (
											<div className="amount_item">
												{this.formatAmount(
													get(this.state.data, 'amount', 0.0)
												)}{' '}
												{get(this.state.data, 'currency')}
											</div>
										)}
									<div className="amount_item">
										{this.formatAmount(
											get(this.state.currentChoice, 'amount', 0.0)
										)}{' '}
										{get(this.state.currentChoice, 'currencyData.symbol')}
									</div>
								</div>
								<button className="close_dialog" onClick={this.close.bind(this, 'close')} />
							</div>
						</div>
					</header>
					<div className="alert_banner" role="alert">
						<div className="banner_label">
							<span className="timer">
								{this.state.timeLeft.min}m:{this.state.timeLeft.sec}s
							</span>
							<span> remaining</span>
						</div>
						<span className="alert_icon">
							<svg className="cust_icon icon_xs" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
								<path fill="#e5e5e5" d="M10,20A10,10,0,1,1,20,10,10,10,0,0,1,10,20Z" />
								<rect fill="#" x="8.75" y="8.88" width="2.5" height="6.75" />
								<rect fill="#" x="8.75" y="4.38" width="2.5" height="2.25" />
							</svg>
						</span>
					</div>
					<div className="alert_banner" style={{ backgroundColor: 'darkred' }} role="alert">
						<div className="banner_label">
							For a successful transaction, ensure you send the amount
							stated below. Click to copy the amount.
						</div>
					</div>
					<div className="widget_panel_body">
						<Popup inverted on={['hover', 'click']} position="top center"
							content={ this.state.amountCopied ? 'Copied!' : 'Click to copy amount' }
							trigger={
								<CopyToClipboard text={get(this.state.transaction, 'amount', 0.0)} onCopy={() => { this.onCopied('amount'); }}>
									<h4 className="step_title copiable">
										Amount Due:{' '}
										<span>
											{get(this.state.transaction, 'amount', 0.0)}{' '}
											{this.getCurrencySymbol(
												get(this.state.transaction, 'currency')
											)}
										</span>
									</h4>
								</CopyToClipboard>
							}
						/>
						<figure className="qr_code_holder text-center">
							<Popup inverted on={['hover', 'click']} position="top center"
								content={ this.state.addressCopied ? 'Copied!' : 'Click to copy address' }
								trigger={
									<CopyToClipboard
										text={get( this.state.transaction, 'destination.address' )}
										onCopy={() => { this.onCopied('address'); }} >
										{this.getQRImage(this.state.transaction) ?
											<Image size='small' className="copiable" alt="Payment Address" inline
												src={this.getQRImage(this.state.transaction)} bordered />
											: <div></div>
										}
									</CopyToClipboard>
								}
							/>
						</figure>
						<figcaption className="text-center">
							<span style={{ fontSize: '12px' }}>
								Scan the QR code above to reveal payment address, or copy
								the given address below.
							</span>
						</figcaption>
						{
							!has(this.state.transaction, 'destination.memo') &&
							<div className="text-center wallet-container">
								<a
									href={this.getWalletUri()}
									target="_blank"
									rel="noopener noreferrer"
									className="btn btn-primary btn-sm"
								>
									Open in Wallet
								</a>
							</div>
						}
						{this.renderCopyAddress()}

						{this.renderDestinationMemo()}

					</div>
					<footer className="widget_panel_footer">
						<div className="action_btn">
							<button type="submit" className="btn btn-default" onClick={() => this.setState({ stage: 0 })}>
								<svg className="cust_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16.36">
									<polygon className="cls-1"
										points="8.8 16.36 10.16 14.89 4.01 9.18 20 9.18 20 7.18 4.01 7.18 10.16 1.47 8.8 0 0 8.18 8.8 16.36"
									/>
								</svg>
								Change Currency
							</button>
						</div>
						<div className="foot_note text-center">
							<span>Secured by Paylot</span>
						</div>
					</footer>
				</section>
			)
		}
	}
	renderDestinationMemo = () => {
		if(get(this.state.transaction, 'destination.memo')) {
			return (
				<div>
					<Popup inverted on={['hover', 'click']} position="top center"
						content={ this.state.memoCopied ? 'Copied!' : 'Click to copy memo' }
						trigger={
							<CopyToClipboard
								text={get( this.state.transaction, 'destination.memo' )}
								onCopy={() => { this.onCopied('memo'); }}
							>
								<div className="form-group text-center">
									<label className="display-label">
										Memo
									</label>
									<input
										type="text"
										className="form-control copiable text-center"
										onFocus={e => e.target.blur()}
										readOnly
										style={{ width: 'unset', margin: 'auto' }}
										value={get(
											this.state.transaction,
											'destination.memo'
										)}
									/>
									<div>
										<span style={{ color: 'red', fontSize: '12px' }}>(Please, ensure you specify memo)</span>
									</div>
								</div>
							</CopyToClipboard>
						}
					/>
				</div>
			)
		}
	}
	renderCopyAddress = () => {
		return (
			<div>
				<Popup
					inverted
					on={['hover', 'click']}
					position="top center"
					content={ this.state.addressCopied ? 'Copied!' : 'Click to copy address' }
					trigger={
						<CopyToClipboard
							text={get( this.state.transaction, 'destination.address' )}
							onCopy={() => { this.onCopied('address'); }}>
							<div className="form-group text-center">
								<label className="display-label">
									Payment Address
								</label>
								<input type="text" className="form-control copiable text-center"
									onFocus={e => e.target.blur()}
									readOnly
									value={get(
										this.state.transaction,
										'destination.address'
									)}
								/>
							</div>
						</CopyToClipboard>
					}
				/>
			</div>
		)
	}

	renderCompletedStage = () => {
		const { busy, stage, complete } = this.state
		if(!busy && stage === 1 && complete) {
			return (
				<section className={`widget_panel_card pay_step step_active`}>
					<header className="widget_panel_header">
						<div className="flex_container">
							<figure className="pay_merchant_logo">
								<img
									src={get(
										this.props.merchant,
										'logo.url',
										'/img/logos/dummy_logo_1.png'
									)}
									alt="Business Logo"
								/>
							</figure>
							<div className="purchase_amount font-bold">
								{get(this.state.currentChoice, 'currencyData.symbol') !==
									get(this.state.data, 'currency') && (
										<div className="amount_item">
											{/* <i className="currency naira"></i> */}
											{get(this.state.data, 'amount', 0.0)}{' '}
											{get(this.state.data, 'currency')}
											{/* {get(first(this.state.txList), 'nairaValue', '****')} */}
										</div>
									)}

								<div className="amount_item">
									{get(this.state.currentChoice, 'amount', 0.0)}{' '}
									{get(this.state.currentChoice, 'currencyData.symbol')}
								</div>
							</div>
							<button
								className="close_dialog"
								onClick={this.close.bind(this, 'close')}
							/>
						</div>
					</header>
					<div className="widget_panel_body">
						{this.state.error && (
							<div>
								<Header as="h3" icon color="red" textAlign="center">
									<Icon name="info circle" />
									{this.state.errorMessage}
									<Header.Subheader>
										<Button
											basic
											onClick={() => window.location.reload(true)}
										>
											Click here to reload
										</Button>
									</Header.Subheader>
								</Header>
							</div>
						)}
						{!this.state.error && (
							<div className="success_message">
								<h3>Payment completed</h3>
								<div className="stat_image">
									<img
										src="/img/banners/success.svg"
										alt="Payment completed"
									/>
								</div>
								<p>
									Your payment of{' '}
									<strong>
										{get(this.state.transaction, 'amount', 0.0)}{' '}
										{this.getCurrencySymbol(
											get(this.state.transaction, 'currency')
										)}
									</strong>{' '}
									has been received successfully.
								</p>
							</div>
						)}
					</div>
					<footer className="widget_panel_footer">
						<div className="action_btn">
							{!this.state.error && (
								<button type="submit" className="btn btn-primary">
									Closing in 5s
								</button>
							)}
						</div>
						<div className="foot_note text-center">
							<span>Secured by Paylot</span>
						</div>
					</footer>
				</section>
			)
		}
	}

	render() {
		return (
			<div id="widget" className="animated fadeIn payment_widget">
				<div className="widget_panel_backdrop" />
				<div className="widget_panel_container payment_steps">
					{this.renderLoading()}

					{this.renderFirstStage()}

					{this.renderNextStage()}

					{this.state.error && (
						<section className={`widget_panel_card pay_step step_active`}>
							<header className="widget_panel_header">
								<div className="flex_container">
									<figure className="pay_merchant_logo">
										<img
											src={get(
												this.props.merchant,
												'logo.url',
												'/img/logos/dummy_logo_1.png'
											)}
											alt="Business Logo"
										/>
									</figure>
									<div className="pay_merchant_details">
										<h4 className="merchant_title">
											{capitalize(this.props.merchant.name || '')}
										</h4>
										<div className="purchase_amount font-bold">
											{/* <div className="amount_item"> */}
											{/* <i className="currency naira"></i> */}
											{/* {get(this.state.data, 'amount', 0.00)} {get(this.state.data, 'currency')} */}
											{/* {get(first(this.state.txList), 'nairaValue', '****')} */}
											{/* </div> */}
											{/* <div className="amount_item"> */}
											{/* {get(this.state.currentChoice, 'amount', 0.00)} {get(this.state.currentChoice, 'currencyData.symbol')} */}
											{/* </div> */}
										</div>
										<button
											className="close_dialog"
											onClick={this.close.bind(this, 'close')}
										/>
									</div>
								</div>
							</header>
							<div className="widget_panel_body">
								{this.renderTimeErrorCard()}

								{this.renderFailedErrorCard()}
							</div>
							<footer className="widget_panel_footer">
								<div className="foot_note text-center">
									<span>Secured by Paylot</span>
								</div>
							</footer>
						</section>
					)}

					{this.renderCompletedStage()}
				</div>
			</div>
		);
	}
}
