import React, { Component } from 'react';
import { Input, Dropdown, Button, Grid, Header, Message } from 'semantic-ui-react';
import { map } from 'lodash';
import axios from 'axios';

export class LinkGenerator extends Component {

  state = {
    busy: true,
    currencyOptions: [],
    link: '',
    linkData: {},
    error: false,
    errorMessage: {}
  };

  tx = {
    deposit: true,
    payload: {
      type: 'payment'
    }
  }

  componentDidMount() {
    this.props.getCurrencies((err, list) => {
      if (!err) {
        let currencyOptions = map(list, (c) => ({ text: c.symbol, value: c.symbol }));
        currencyOptions.push({ text: 'USD', value: 'USD' });
        currencyOptions.push({ text: 'EUR', value: 'EUR' });
        currencyOptions.push({ text: 'GBP', value: 'GBP' });
        this.setState({ busy: false, currencyOptions });
      }
    })
  }

  generateLink() {
    let api = process.env.REACT_APP_API_URL;
    let widgetUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.REACT_APP_WIDGET_URL;
    this.setState({ busy: true }, () => {
      axios({
        method: 'POST',
        url: `${api}/sign`,
        data: this.tx
      })
        .then((res) => {
          console.log(res.data);
          let txString = btoa(JSON.stringify(res.data));
          this.setState({ busy: false, error: false, linkData: res.data, link: `${widgetUrl}/payment/${txString}` });
        })
        .catch((err) => {
          console.log(err);
          this.setState({ busy: false, error: true, errorMessage: err.response })
        });
    });
  }

  render() {
    return (
      <div>
        <Grid stackable padded  >
          <Grid.Row columns={3}>
            <Grid.Column>
            </Grid.Column>
            <Grid.Column>
              <div>
                <Header>Basic Data</Header>
                <label>Merchant Secret *</label>
                <Input
                  fluid
                  placeholder='Merchant Secret'
                  defaultValue={this.tx.secret}
                  onChange={(e, { value }) => this.tx.secret = value}
                />
                <br />
                <label>Merchant ID *</label>
                <Input
                  fluid
                  placeholder='Merchant ID'
                  defaultValue={this.tx.merchant}
                  onChange={(e, { value }) => this.tx.merchant = value}
                />
                <br />
                <label>Amount *</label>
                <Input
                  fluid
                  placeholder='Amount'
                  defaultValue={this.tx.amount}
                  onChange={(e, { value }) => this.tx.amount = Number(value)}
                />
                <br />
                <label>Currency *</label>
                <Dropdown
                  placeholder='Currency'
                  fluid
                  search
                  selection
                  disabled={this.state.busy}
                  options={this.state.currencyOptions}
                  onChange={(e, { value }) => {
                    this.tx.currency = value;
                  }}
                />
                <Header>Payload Data</Header>
                <label>Type *</label>
                <Input
                  fluid
                  placeholder='Type'
                  defaultValue={this.tx.payload.type}
                  onChange={(e, { value }) => this.tx.payload.type = value}
                />
                <br />
                <label>Subject</label>
                <Input
                  fluid
                  placeholder='Subject'
                  defaultValue={this.tx.payload.subject}
                  onChange={(e, { value }) => this.tx.payload.subject = value}
                />
                <br />
                <label>Email</label>
                <Input
                  fluid
                  placeholder='Email'
                  defaultValue={this.tx.payload.email}
                  onChange={(e, { value }) => this.tx.payload.email = value}
                />
                <br />
                <label>Send Mail ?</label>
                <Dropdown
                  placeholder='YES/NO'
                  fluid
                  search
                  selection
                  disabled={this.state.busy}
                  options={[
                    { text: 'YES', value: 'true' },
                    { text: 'NO', value: 'false' }
                  ]}
                  onChange={(e, { value }) => {
                    this.tx.payload.sendMail = Boolean(value);
                  }}
                />
                <br />
                <label>Callback URL</label>
                <Input
                  fluid
                  placeholder='Callback'
                  defaultValue={this.tx.payload.callback}
                  onChange={(e, { value }) => this.tx.payload.callback = value}
                />
                <br />
                <Button
                  fluid
                  disabled={this.state.busy}
                  loading={this.state.busy}
                  onClick={this.generateLink.bind(this)}
                >
                  Generate
                </Button>
              </div>
            </Grid.Column>
            <Grid.Column>
              {
                this.state.link &&
                <Message info style={{ wordWrap: 'break-word' }}>
                  <Message.Header>
                    Payment Link
                  </Message.Header>
                  <a href={this.state.link} target='_blank' rel="noopener noreferrer">
                    {this.state.link}
                  </a>
                </Message>
              }
              {
                this.state.link &&
                <Message info style={{ wordWrap: 'break-word' }}>
                  <Message.Header>
                    Payment Data
                  </Message.Header>
                  <pre>
                    {JSON.stringify(this.state.linkData, null, 2)}
                  </pre>
                </Message>
              }
              {
                this.state.error &&
                <Message error style={{ wordWrap: 'break-word' }}>
                  <Message.Header>
                    Error Data
                  </Message.Header>
                  <pre>
                    {JSON.stringify(this.state.errorMessage, null, 2)}
                  </pre>
                </Message>
              }
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }
}