import { connect } from 'react-redux';

import { Main } from './main';
import { getCurrencies } from '../../actions';

const mapStateToProps = (state) => {
  return {

  }
}

const VisibleMain = connect(mapStateToProps, {
  getCurrencies
})(Main);

export default VisibleMain;
