
function useMatomo() {
    const instance = window.matomo

    const trackPageView = (params) =>
        instance.trackPageView && instance.trackPageView(params)

    const trackEvent = (params) =>
        instance.trackEvent && instance.trackEvent(params)

    const trackSiteSearch = (params) =>
        instance.trackSiteSearch && instance.trackSiteSearch(params)

    return {
        trackEvent,
        trackPageView,
        trackSiteSearch,
    }
}

export default useMatomo