import axios from 'axios';

import {
    GET_CURRENCIES,
    RES_GET_CURRENCIES,
    GET_MERCHANT,
    RES_GET_MERCHANT
} from './types';

const api = process.env.REACT_APP_API_URL;

export function getCurrencies(callback) {
    return (dispatch) => {
        dispatch({
            type: GET_CURRENCIES
        });
        axios(`${api}/currencies`)
            .then((res) => {
                dispatch({
                    type: RES_GET_CURRENCIES,
                    currencies: res.data
                })
                callback && callback(null, res.data);
            })
            .catch((err) => {
                dispatch({
                    type: RES_GET_CURRENCIES,
                    error: err
                })
                callback && callback(err, null);
            });
    }
}

export function getMerchant({ id, key }, callback) {
    return (dispatch) => {
        dispatch({
            type: GET_MERCHANT
        });
        let url = key ? `${api}/merchants/key/${key}` : `${api}/merchants/${id}`;

        axios(url)
            .then((res) => {
                dispatch({
                    type: RES_GET_MERCHANT,
                    merchant: res.data
                })
                callback && callback(null, res.data);
            })
            .catch((err) => {
                dispatch({
                    type: RES_GET_MERCHANT,
                    error: err
                })
                callback && callback(err, null);
            });
    }
}